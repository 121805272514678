import {
  Login,
  Scans,
  AppVersionsScreen,
  QatValidationsScreen,
  QatValidationDetailsScreen,
  StoresScreen,
  MLScreen,
} from 'views';
import { Config } from 'configs';
import { BaseLayout } from 'components/Layouts/BaseLayout';
import { BlankLayout } from 'components/Layouts/BlankLayout';
import { UploadStructureScreen } from 'views/UploadStructure';
import { StatisticsScreen } from 'views/Statistics';
import { ScanSessionsReport } from 'views/ScanSessionsReport';

export const routes = {
  scans: {
    path: ['/', '/scan-sessions'],
    component: Scans,
    exact: true,
    Roles: [Config.ROLES.ADMIN, Config.ROLES.CUSTOMER, Config.ROLES.QAT],
    layout: BaseLayout,
  },
  stores: {
    path: ['/stores'],
    component: StoresScreen,
    exact: true,
    Roles: [Config.ROLES.ADMIN, Config.ROLES.CUSTOMER],
    layout: BaseLayout,
  },
  appVersionsUsage: {
    path: ['/app-versions-usage'],
    component: AppVersionsScreen,
    exact: true,
    Roles: [Config.ROLES.ADMIN, Config.ROLES.CUSTOMER],
    layout: BaseLayout,
  },
  uploadStructureScreen: {
    path: ['/uploads'],
    component: UploadStructureScreen,
    exact: true,
    Roles: [Config.ROLES.ADMIN],
    layout: BaseLayout,
  },
  scanSessionsReport: {
    path: ['/scan-sessions-report'],
    component: ScanSessionsReport,
    exact: true,
    Roles: [Config.ROLES.ADMIN],
    layout: BaseLayout,
  },
  statisticsScreen: {
    path: ['/statistics'],
    component: StatisticsScreen,
    exact: true,
    Roles: [Config.ROLES.ADMIN],
    layout: BaseLayout,
  },
  qat: {
    path: ['/qat-validations'],
    component: QatValidationsScreen,
    exact: true,
    Roles: [Config.ROLES.QAT, Config.ROLES.ADMIN],
    layout: BaseLayout,
  },
  qatValidation: {
    path: ['/qat-validation-details'],
    component: QatValidationDetailsScreen,
    exact: true,
    Roles: [Config.ROLES.ADMIN, Config.ROLES.QAT],
    layout: BaseLayout,
  },
  ml: {
    path: ['/ml'],
    component: MLScreen,
    exact: true,
    Roles: [Config.ROLES.ADMIN],
    layout: BaseLayout,
  },
  // scannersUsageGrapth: {
  //   path: ['/scanners-usage-graph'],
  //   component: ScannersUsageGraph,
  //   exact: true,
  //   Roles: [Config.ROLES.ADMIN, Config.ROLES.CUSTOMER],
  //   layout: BaseLayout,
  // },
  login: {
    path: '/sign-in',
    component: Login,
    exact: true,
    layout: BlankLayout,
    Roles: [Config.ROLES.UNAUTHINCATED],
    redirectTo: '/scan-sessions',
  },
};
